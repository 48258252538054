import httpRequest from "../../util/http";


export const apiGetConfig = async (countryId, brandId, rejectWithValue) => {
  return await httpRequest("/configResponse.json")
    .then((response) => response.json())
    .then((data) => {      
      var index = data.findIndex((config) => config.countryId === countryId && config.brandId === brandId);
      if (index === -1) {      
        return null;
      }       
      return data[index];
    })
    .catch(error => {
      console.log(error)
      if (error.name !== 'AbortError') throw rejectWithValue({ httpStatus: 503 });
      else throw rejectWithValue({});
    });;
} 