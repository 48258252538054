// Externals
import { combineReducers } from '@reduxjs/toolkit';

// Reducers
import address from './address';
import auth from './auth';
import contact from './contact';
import documents from './documents';
import policy from './policy';
import brand from './brand';
import payment from './payment'
import config from './config';

// Root Reducer
const rootReducer = combineReducers({
	auth: auth,
	address: address,
	brand: brand,
	config: config,
	contact: contact,
	documents: documents,
	policy: policy,
	payment: payment
});

// Export Root Reducer
export default rootReducer;
