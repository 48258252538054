// Externals
import React from 'react';
import { useSelector } from 'react-redux';

//Selectors
import { policyChangeCTASelector } from '../../store/selectors/brand';

// Components
import PolicyTitle from './Title';
import DetailPolicySummary from './subcomponents/DetailPolicySummary';
import PaymentDetails from './subcomponents/PaymentDetails';
import LifeInsureds from './subcomponents/LifeInsureds';
import BeneficiaryDetails from './subcomponents/BeneficiaryDetails';

// Styles
import './Detail.scss';

// Policy Summary component
const Detail = (props) => {
	const { policyNumber } = props;
	const policy = useSelector(state => state.policy.policyDetails[policyNumber]);
	const isInactivePolicy = policy.PolicyStatus.PolicyStatusId >= 50;
	const policyChangeCta = useSelector(policyChangeCTASelector);
	return (
		<div className='policy-detail'>
			<PolicyTitle policy={policy} />
			<p className='policy-change-text' dangerouslySetInnerHTML={{ __html: policyChangeCta}}></p>
			<DetailPolicySummary policy={policy} isInactivePolicy={isInactivePolicy}/>
			{!isInactivePolicy && 
				<>
					<PaymentDetails policy={policy}/>
					<LifeInsureds policy={policy}/>
					<BeneficiaryDetails policy={policy}/>
				</>
			}		
		</div>
	);
};

// Exports
export default Detail;
