// Externals
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

// Selectors
import { policyDetailSelector } from '../../store/selectors/policy';
import { policyDocumentsSelector } from '../../store/selectors/documents';

// Components
import PolicyTitle from '../Policy/Title';

// Utility
import { formatDate } from '../../util/formats';

// Styles
import './List.scss';
import { dateFormatRegionSelector } from '../../store/selectors/config';

// Documents List
const DocumentList = (props) => {
	const { policyNumber } = props;
	const [ documentSort, setDocumentSort ] = useState('desc');
	const [ eventSort, setEventSort ] = useState(null);

	const policy = useSelector(state => policyDetailSelector(state, policyNumber));
	const documentsList = useSelector(state => policyDocumentsSelector(state, policyNumber, documentSort, eventSort));
	const dateFormatRegion = useSelector(dateFormatRegionSelector);
	//AUPortals TODO: Document retrievals are geared toward quadient only. May need to have views and actions for each provider or something. Disabled in AUS
	//ATM so can ignore for now.
	const handleOpenDocument = (id) => {
		window.open('/documents/' + policyNumber + '/view?docId=' + id)
	}

	const handleDocumentSort = () => {
		setEventSort(null);
		setDocumentSort(documentSort === null ? 'desc' : documentSort === 'desc' ? 'asc' : 'desc');
	}

	const handleEventSort = () => {
		setDocumentSort(null);
		setEventSort(eventSort === null ? 'desc' : eventSort === 'desc' ? 'asc' : 'desc');
	}
	
	return (
		<div className='documents-list'>
			<PolicyTitle policy={policy} key="title" />
			<p>You can read important policy information and past communication below:</p>
			<div className='table-wrapper'>
				<table className='desktop' key="desktop">
					<tbody>
						<tr key="headings">
							<th><button onClick={handleDocumentSort}>Date</button></th>
							<th><button onClick={handleEventSort}>Subject</button></th>							
							<th>Document</th>
						</tr>
						{documentsList && documentsList.length > 0 && documentsList.map((documents, rootIndex) => {
							return <Fragment key={rootIndex}>
							 {documents.map((document, index)=> (
								<tr key={rootIndex + "-" + index} className={index === documents.length - 1 && rootIndex !== documentsList.length - 1 ? "bordered" : ""}>
									<td>{index === 0 ? document.FolderFields.CorrespondenceDate ? formatDate(document.FolderFields.CorrespondenceDate, dateFormatRegion) : formatDate(policy.PolicySummary.FirstCollectionDate, dateFormatRegion) : ""}</td>
									<td>
										{index === 0 ? document.FolderFields.CorrespondenceType : ""}
									</td>
									<td>
										<button onClick={() => handleOpenDocument(encodeURIComponent(document.DocId))}>											
											{document.FolderFields.FileName}
										</button>
									</td>
								</tr>
							))}
							</Fragment>})}
					</tbody>
				</table>
				<table className='mobile'>
					<tbody>
						<tr key='headings'>
							<th>Document</th>
							<th><button onClick={handleDocumentSort}>Date</button></th>
						</tr>
						<>
						{documentsList && documentsList.length > 0 && documentsList.map((documents, rootIndex) => (
							<Fragment key={"fragment-" + rootIndex}>
							{documents.map((document, index) => (<>
								<tr key={'document-' + index}>
									<td colSpan='3'>
										<button onClick={() => handleOpenDocument(document.DocId)}>
											{document.FolderFields.FileName}
										</button>
									</td>
								</tr>
								<tr key={'meta-' + index} className='meta'>
									<td>
										{document.FolderFields.CorrespondenceType}
									</td>
									<td>{document.FolderFields.CorrespondenceDate ? formatDate(document.FolderFields.CorrespondenceDate, dateFormatRegion) : formatDate(policy.PolicySummary.AcceptanceDate, dateFormatRegion)}</td>
								</tr></>
						))}
							
						</Fragment>))}</>
					</tbody>
				</table>
			</div>
		</div>
	);
};

// Export
export default DocumentList;
