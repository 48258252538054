// Login

// ENV Config
import config from '../../config';
import httpRequest from '../../util/http';

// Base Host for API calls
const apiHost = config.API_HOST;
const recaptchaHost = config.RECAPTCHA_HOST;
const brandId = config.BRAND_ID;

// 
export const apiCustomerLogin = async (emailAddress, mobileNumber, dob, rejectWithValue) => {
	const url = apiHost + '/api/v1/Account/customerlogin';

	const payload = {
		BrandId: brandId,
		EmailId: emailAddress ? emailAddress : "",
		PhoneNumber: mobileNumber ? mobileNumber : "",
		DateOfBirth: dob
	};
	
	const response = await httpRequest(url, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(payload)
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const CustomerPortalLoginGuid = await response.text();
		// strip the GUID of leading and trailing quotes
		return CustomerPortalLoginGuid.replace(/^"(.*)"$/, '$1');
	}
}

// 
export const apiValidateOTP = async (otp, customerLoginId, rejectWithValue) => {
	const url = apiHost + '/api/v1/Account/validateotp';

	const payload = {
		securitycode: otp,
		customerportalloginid: customerLoginId
	};
	
	const response = await httpRequest(url, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(payload)
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

// 
export const apiResendOTP = async (customerLoginId, rejectWithValue) => {
	const url = apiHost + '/api/v1/Account/resendotp';

	const payload = {
		CustomerPortalLoginId: customerLoginId
	};
	
	const response = await httpRequest(url, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(payload)
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

// Call the server to expire the bearer token
export const apiCustomerLogoutServer = async (token, rejectWithValue) => {
	const url = apiHost + '/api/v1/Customer/logout';
	
	const response = await httpRequest(url, {
		method: 'POST',
		headers: { 
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json'
		}
	});
	// this logout call should never throw the user to 503 page
	// .catch(() => {
	// 	throw rejectWithValue({ httpStatus: 503 });
	// });

	if (!response.ok) {
		// return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}


/***
 *  Params is composed of a recaptcha token, the site key and the action to be recorded in recaptcha
 *  returns Success: boolean && Score: number
 */
export const apiVerifyRecaptcha = async (params, rejectWithValue) => {
	if(recaptchaHost === undefined){
		return{
			success: true,
			score: 1
		}
	}

	const url = recaptchaHost + '/api/v1/recaptcha/verify';
	const response = await httpRequest(url, {
		method: 'POST',
		headers:{
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(params)
	});

	if(response.ok){
		const data = await response.json();
		return data;
	} else {
		//allow login to continue when there are outages with recaptcha.
		return {
			success: true,
			score: 1
		}
	}
}
