import React from "react";
import { useSelector } from 'react-redux';
import { beneficiaryDetailsLinkSelector } from '../../../store/selectors/brand';

// Selectors
import { headingSelector } from '../../../store/selectors/brand';
import { dateFormatRegionSelector } from "../../../store/selectors/config";

// Utils
import { formatDate } from "../../../util/formats";

// Components
import Table from "../../Layout/Table";
import TableRow from "../../Layout/TableRow";


const BeneficiaryDetails = ({policy}) => {    
    const beneficiaryHeaders = ['Beneficiary Name', 'Beneficiary Date of Birth', 'Proportion of Benefit %'];
    const sectionHeading = useSelector(state => headingSelector(state, 'beneficiaryDetails'));
    const sortedBeneficiaries = [...policy.Beneficiaries].sort((a, b) => b.Percentage - a.Percentage);
    const beneficiaryDetailsLink = useSelector(beneficiaryDetailsLinkSelector);
    const dateFormatRegion = useSelector(dateFormatRegionSelector);
    return (
        <>            
            {/* 9442: Added beneficiary link specific to the brand and adjusted CSS to render heading and link together */}
            <div className='payment-detail-header detail-heading'>
                <h3>{sectionHeading}</h3>
                <span className='edit-link-container' dangerouslySetInnerHTML={{ __html: beneficiaryDetailsLink}}></span>
            </div>
            {policy.Beneficiaries.length > 0 &&
                <>
                    <Table key={0} className={'policy-detail-table'} headers={beneficiaryHeaders}>
                        { sortedBeneficiaries.map((ben, index) => (
                            <TableRow key={index} valueClassName='dd-privacy-mask' values={[ben.FirstName + ' ' + ben.LastName, formatDate(ben.BirthDate, dateFormatRegion), ben.Percentage + '%']}/>
                        ))}
                    </Table>

                    
                    { sortedBeneficiaries.map((ben, i) => {
                        const values = [ben.FirstName + ' ' + ben.LastName, formatDate(ben.BirthDate, dateFormatRegion), ben.Percentage + '%'];
                        return <Table key={'m-' + i} className={'policy-detail-table-mobile'}>
                            { beneficiaryHeaders.map((header, index) => (
                            <TableRow key={index} valueClassName='dd-privacy-mask' heading={header} value={values[index]} />
                        )) }
                        </Table>
                    })}
                </>
            }

            {policy.Beneficiaries.length === 0 && <>                
                <Table key={0} className={'policy-detail-table show-on-mobile'} headers={['Beneficiaries']}>
                    <TableRow key={0} values={['Currently there is no beneficiary listed on this policy']}/>
                </Table>
            </>}
           
        </>
    )
}

export default BeneficiaryDetails;
