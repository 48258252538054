import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//util
import { formatISODate } from '../../util/formats';
import { addMaskAfterFormLoad } from '../../util/domUtils'

// Constants
import { creditCard, directDebit } from '../../components/Forms/PaymentMethodSwitch';

// Components
import Spinner from '../../components/Widgets/Spinner';
import PaymentMethodSwitch from '../../components/Forms/PaymentMethodSwitch';
import FlashMessage from '../../components/Widgets/FlashMessage';

//Actions
import { clearUpdatePaymentInfo, getPaymentDetailForm, updatePaymentDetails } from '../../store/actions/payment';
import { getPolicyDetail } from '../../store/actions/policy';
import { clearInvalid, markInvalid } from '../../util/validate';
import { paymentUpdateFailureMessageSelector } from '../../store/selectors/brand';
import { creditCardPaymentProviderSelector, paymentsParameterSelector, updatePaymentDetailsPageConfigSelector } from '../../store/selectors/config';

export const paymentDetailComponentId = "payment-detail-component";

const UpdatePaymentForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { policyNumber } = useParams();
	const policy = useSelector(state => state.policy.policyDetails[policyNumber]);
	const formPayload = useSelector(state => state.payment.formPayload);
	const loading = useSelector(state => state.payment.loading);
	const updateSuccess = useSelector(state => state.payment.updateSuccess);
	const updatePaymentFailureMessage = useSelector(paymentUpdateFailureMessageSelector);
	const [componentLoaded, setComponentLoaded] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(directDebit);
	const [stripeHasLoaded, setStripeLoaded] = useState(false);
	const [updatePaymentButtonDisabled, setUpdatePaymentButtonDisabled] = useState(false);
	const [confirmation, setConfirmation] = useState(false);
	const [queryParams] = useSearchParams();
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const paymentRegisterRef = useRef(null);
	const confirmationRef = useRef(null);
	const paymentParameters = useSelector(paymentsParameterSelector);
	const pageConfig = useSelector(updatePaymentDetailsPageConfigSelector);
	
	var stripeCheck = null;
	const handleSwitchClick = (method) => {
        if(paymentMethod !== method){
					setPaymentMethod(method);
					if(paymentParameters.creditCardProvider === 2 && !stripeHasLoaded && method === 0){	
						setUpdatePaymentButtonDisabled(true);
						stripeCheck = setInterval(() => {
							if(document.querySelector(".__PrivateStripeElement")){			
								setStripeLoaded(true);
								clearInterval(stripeCheck);
								setTimeout(() => {
									setUpdatePaymentButtonDisabled(false);
								}, 3000)
							}
						}, 500);
					} else {
						setUpdatePaymentButtonDisabled(false);
					}
        }
    }

	const handleFormSubmit = () => {
		if (!confirmation) {
			markInvalid(confirmationRef);
		}
		else {
			document.querySelector('#btn_ac_submit').click();
		} 
		handleScrollToInvalid();
	}

	const handleCancel = () => {
		navigate('/policy/' + policyNumber, { replace: true });
	}

	const handleToggleTerms = ({ target: { checked }}) => {
		setConfirmation(checked);
		if (checked) clearInvalid(confirmationRef);
		else markInvalid(confirmationRef);
	}

	const handleScrollToInvalid = () => {
		// Scroll first invalid field into view
		const firstInvalid = document.querySelectorAll('input.errorBox');
		if (firstInvalid.length) {
			setTimeout(() => { firstInvalid[0].scrollIntoView({ behavior: 'smooth' })}, 500);
		}
		else {
			const nextInvalid = document.querySelectorAll('.confirmation-container.invalid > input');
			if (nextInvalid.length) {
				nextInvalid[0].scrollIntoView({ behavior: 'smooth' });
			}
		}
	}

	const navigateToUpdatedPolicy = useCallback(() =>{
		dispatch(getPolicyDetail(policyNumber));
		navigate('/policy/' + policyNumber + '?success=1');
	}, [policyNumber, dispatch, navigate]);

	useEffect(()=>{
		//Prevent reload of form payload if there are query params present in url
		//because we want to preserve formPayload.EvolveUpdatePaymentDetailsToken for the second request (CC only - updatePaymentDetails)
		if(policy && !queryParams.has('accountType')){
			const params = {
				PolicyParameter: {
					policyNumber: policy.PolicySummary.PolicyNumber + "",
					firstName: policy.Owner.FirstName,
					surName: policy.Owner.Lastname,
					dob: formatISODate(policy.Owner.BirthDate),
					clientIPAddress: '127.0.0.1'
				}
			};
			//Reload form payload
			dispatch(getPaymentDetailForm(params));
		}
	}, [dispatch, policy, queryParams]);

	useEffect(() => {
		const id = "payment-detail-component";

		//Checks for script tag with above id and creates one if its not present + we have the url for the script 
		if(!document.getElementById(id) && formPayload.GfsWebComponentsUrl){
				const script = document.createElement("script");
				script.id = id
				script.src = formPayload.GfsWebComponentsUrl;
				script.async = true;
				script.onload = function () {
					setComponentLoaded(true);
				};
				document.head.appendChild(script);	
		} else if(formPayload.GfsWebComponentsUrl){ 
			//Let the ui know that we have the script ready
			//We only want to add the angular component to the dom after the script is ready, or nothing will happen
			setComponentLoaded(true);
		}

	}, [formPayload.GfsWebComponentsUrl]);

	useEffect(()=> {
		//once form has been submitted, use the parameters to call updatePaymentDetails (or redirect for DD)
		if(!loading && updateSuccess === null){
			if(queryParams.has('accountType') && queryParams.get('accountType') === 'DD')
			{
				queryParams.set('token', formPayload.EvolveUpdatePaymentDetailsToken);
				dispatch(updatePaymentDetails(queryParams));
				// update policy details in redux and go back to policy details				
				navigateToUpdatedPolicy();
			} else if(paymentParameters.creditCardProvider === 2 && queryParams.has('setup_intent') && queryParams.get('redirect_status') === 'succeeded') {
				queryParams.set('token', formPayload.EvolveUpdatePaymentDetailsToken);
				queryParams.set('action', 'registered');
				queryParams.set('accountType', 'CC');
				queryParams.set('cd_crn', '');
				setHasSubmitted(true);
				// Request and store CC token from westpac
				dispatch(updatePaymentDetails(queryParams));
				queryParams.delete('redirect_status');
			} else if(paymentParameters.creditCardProvider === 1 && queryParams.has('accountType') && queryParams.get('accountType') === 'CC') {
				queryParams.set('token', formPayload.EvolveUpdatePaymentDetailsToken);			
				// Request and store CC token from westpac
				dispatch(updatePaymentDetails(queryParams));
		} 
	}
	}, [formPayload, policyNumber, loading, queryParams, updateSuccess, dispatch, navigateToUpdatedPolicy])

	useEffect(() => {				
		//Handle success or failure of the CC token request
		if(updateSuccess === true){		
			// update policy details in redux and go back to policy details view
			navigateToUpdatedPolicy();
		} else if(updateSuccess === false) {
			dispatch(clearUpdatePaymentInfo());
			// Reload the page and remove the query params
			navigate(window.location.pathname + '?error=true');
		}
	}, [updateSuccess, policyNumber, navigate, navigateToUpdatedPolicy, dispatch])

	useEffect(() => {
		if(!loading && componentLoaded && paymentMethod === creditCard){
			const ccNumField = document.querySelector('#no_credit_card');
			const ccExpYear = document.querySelector('#cc_expiry_year');
			if(ccNumField && ccExpYear){
				if(!ccNumField.hasAttribute('autocomplete')){
					ccNumField.setAttribute('autocomplete', 'cc-number');
				}

				if(!ccExpYear.hasAttribute('maxlength')){
					ccExpYear.setAttribute('maxlength', '2');
				}
			}
		}
	}, [loading, componentLoaded, paymentMethod])

	



    return (
        <>
            { (queryParams.has('error') && !loading) &&
                <FlashMessage message={updatePaymentFailureMessage} error={true} setHtml={true}/>
            }

            { (loading) &&
                <Spinner />
            }

            { !loading && componentLoaded && !hasSubmitted &&
                <>
                    <PaymentMethodSwitch paymentMethod={paymentMethod} setPaymentMethod={handleSwitchClick} />
                    <div className={'payment-detail-form' + (paymentMethod === creditCard ? ' credit-card-form' : ' direct-debit-form')}>
						{paymentMethod === 0 && paymentParameters.creditCardProvider === 2 && !stripeHasLoaded && <Spinner/>}
                        <payment-register
                            ref={paymentRegisterRef}
                            applicationName="Evolve"
                            gfsauthtoken={formPayload.PaymentGatewayAuthToken.access_token }
                            hidebutton="true"
                            environment={formPayload.PaymentGatewayAPIEnvironment }
                            tokenizedd="false"
                            clientid={formPayload.ClientID}
                            quoteid={formPayload.QuoteID}                                 
														actionurl={ window.location.href.split('?')[0]}
														paymentapiurl={ formPayload.PaymentGatewayAPIURL }
														formid="update-details-form-hfs"
														disablevalidationstr="false"
														showcreditcardstr={paymentMethod === creditCard}													
														bankinfoname={paymentParameters.bankInfoName}
														quotetoken={formPayload.EvolveUpdatePaymentDetailsToken}														                          														
														region={paymentParameters.region}
														showccinparts={paymentParameters.showCreditCardInParts} //string "true" / "false"
														showstripe={paymentParameters.creditCardProvider == 2 ? "true" : "false" }
														showccnamefirst={paymentParameters.showCreditCardNameFirst} //string "true" / "false" 
														crnsuffix={paymentParameters.crnSuffix}
                        >
                        </payment-register>		
                        <div ref={confirmationRef} className='confirmation-container'>
                            <input type='checkbox' name='confirmation' value={confirmation} onChange={handleToggleTerms} required/>
                            <label htmlFor='confirmation'>By selecting this checkbox, you confirm that you are {pageConfig.updatePaymentDisclaimerAuthorised} to use the updated payment details for <b>{policy.Product.ProductName}</b> and that you agree for all future {policy.PaymentFrequency.Description.toLowerCase()} premiums for your <b>{policy.Product.ProductName}</b> {pageConfig.updatePaymentDisclaimerDebitFrom}</label>
                        </div>
                        <div className='btn-container multi-button-container'>
													<button className='form-btn btn-rounded' type="submit" onClick={handleFormSubmit} disabled={updatePaymentButtonDisabled}>{pageConfig.savePaymentDetailsButtonText}</button>                            	
													<button className='form-btn btn-rounded cancel secondary' onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>	
                </>
            }
        </>
    )
}


export default UpdatePaymentForm;
