// Externals
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { addSelectedPayNowTransaction, removeSelectedPayNowTransaction } from '../../store/actions/payment';

// Selectors
import { allOutstandingPaymentsLoadedSelector, overduePolicyPaymentsSelector, policyDetailSelector } from '../../store/selectors/policy';
import { selectedPayNowTransactionIDsSelector, selectedPayNowTransactionIDsTotalAmountSelector } from '../../store/selectors/payment';

// Utility
import { formatMoneyWithDecimal } from '../../util/formats';
import { paymentDetailComponentId } from './UpdatePaymentForm'

// Styles
import './SelectPayments.scss';
import { payNowPageConfigSelector } from '../../store/selectors/config';

// Constant
export const creditCard = 0;

// Widget to select outstanding payments, ready for processing
const SelectPayments = ({ handlePayment, handleCancel }) => {

	//Reloading the page to remove payment gateway component dependencies as its clashing with realtime payment gateway component
	//Bug 71150: Customer Portal Real - Error entering Credit card details under Pay now
	if(document.getElementById(paymentDetailComponentId)){
		window.location.reload(); 
	}

	const dispatch = useDispatch();
	const { policyNumber } = useParams();
	const policy = useSelector(state => policyDetailSelector(state, policyNumber));
	const overduePayments = useSelector(state => overduePolicyPaymentsSelector(state, policyNumber));
	const selectedTransactionIDs = useSelector(selectedPayNowTransactionIDsSelector);
	const totalAmount = useSelector(selectedPayNowTransactionIDsTotalAmountSelector);
	const allOutstandingPaymentsLoaded = useSelector(allOutstandingPaymentsLoadedSelector);
	//Azure-88692: If policy is within the Extended Dishonour Period then all outstanding payments must be paid to bring the policy back in force.
	//EDP Status ID 1 = Active (i.e. Policy is within the Extended Dishonour Period)
	//EDP Status ID 2 = Active Payment Pending (i.e. Still in EDP but a Payment Arrangement has been queued but not sent to bank yet)
	const isPolicyInEdp = (policy.PolicySummary.ExtendedDishonourPeriodStatusId === 1 || policy.PolicySummary.ExtendedDishonourPeriodStatusId === 2);

	const pageConfiguration = useSelector(payNowPageConfigSelector);

	const handleCheckbox = (e) => {
		const transaction = overduePayments.filter(item => item.transactionId === Number(e.target.value))[0];
		delete(transaction.dueDate); // harmless
		if (e.target.checked)
			dispatch(addSelectedPayNowTransaction(transaction));
		else
			dispatch(removeSelectedPayNowTransaction(transaction));
	}

	const checkDisabled = (index) => {
		if (index === 0) return false;
		else if (selectedTransactionIDs.includes(String(overduePayments[index-1].transactionId))) { 
			return false; 
		}
		else {
			dispatch(removeSelectedPayNowTransaction((overduePayments[index])));
			return true;
		}
	}

	useEffect(() => {
		window.scrollTo({ top: 0, behaviour: 'smooth'});	
	}, []);

	return (
		<div className='form form-select-payments'>

			{allOutstandingPaymentsLoaded && overduePayments !== null && overduePayments.length > 0 && (
				<div className='select-payments-table'>
					{overduePayments.map((payment, index) => {
						var disabled = checkDisabled(index);
						if(isPolicyInEdp)
						{
							disabled = true; //During the Extended Dishonour Period the Client should not be able to unselect any transactions.
							dispatch(addSelectedPayNowTransaction(payment)); //Transactions must all be added to the selected list.
						}
						return (
							<div key={index} className={'select-payments-row' + (disabled ? ' disabled' : '')}>
								<span key={payment.transactionId}>{payment.dueDate}</span>
								<span className='amount'>
									{formatMoneyWithDecimal(payment.amount)}
									<input 
										type='checkbox' name='transactionId' 
										value={payment.transactionId}
										checked={isPolicyInEdp || selectedTransactionIDs.includes(String(payment.transactionId))}
										onChange={handleCheckbox}
										disabled={disabled} />
								</span>
							</div>
						);
					})}
				</div>
			)}

			<div className='total-outstanding'>
				<span>Total Outstanding</span>
				<span className='total-amount'>{formatMoneyWithDecimal(totalAmount)}</span>
			</div>

			<div className='action-buttons multi-button-container'>
				{
					//Merge TODO: Sentence casing differences
				}			
				<button className='btn form-btn btn-rounded submit' onClick={handlePayment} disabled={selectedTransactionIDs.length === 0}>{pageConfiguration.continueToPaymentButtonText} &gt;</button>
				<button className='btn form-btn btn-rounded submit cancel secondary' onClick={handleCancel}>Cancel</button>
			</div>

		</div>
	);
};

export default SelectPayments;
