// Contacts

// ENV Config
import config from '../../config';
import httpRequest from '../../util/http';

// Base Host for API calls
const apiHost = config.API_HOST;

// TODO the brandId should be set by the theme FE

export const apiGetCustomerDetails = async (ClientPortalLoginId, token, rejectWithValue) => {
	const url = apiHost + '/api/v1/Customer/getcustomerdetail';

	const payload = {
		SecurityToken: token,
		ClientPortalLoginId: ClientPortalLoginId
	};
	
	const response = await httpRequest(url, {
		method: 'POST',
		headers: { 
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

export const apiUpdateCustomerContact = async (ClientPortalLoginId, contactDetails, token, rejectWithValue) => {

	const url = apiHost + '/api/v1/Customer/updatecustomercontactdetail';

	const payload = {
		ClientPortalLoginId: ClientPortalLoginId,
		ContactDetails: contactDetails
	}

	const response = await httpRequest(url, {
		method: 'POST',
		headers: { 
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});
	
	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}
