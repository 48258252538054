// Policy Lookup

// ENV Config
import config from '../../config';
import httpRequest from '../../util/http';

// Base Host for API calls
const apiHost = config.API_HOST;

export const apiGetPolicyDocumentList = async (args, ClientPortalLoginId, token, rejectWithValue) => {
	const url = apiHost + '/api/v1/Document/documentlist';

	const params = '?PolicyNumber=' + args.PolicyNumber
		+ '&BrandId=' + args.BrandId
		+ '&ClientPortalLoginId=' + ClientPortalLoginId
		+ '&ProductCode=' + args.ProductCode
		+ '&SaleCompletedDate=' + args.SaleCompletedDate;

	const response = await httpRequest(url + params, {
		method: 'GET',
		headers: { 
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json'
		}
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

