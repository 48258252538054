import React, { useState } from 'react';

const NotificationPill = ({ children, lsKey }) => {
  const [keyValue, setKeyValue] = useState(() => {
      const saved = localStorage.getItem(lsKey);
      return saved !== null ? JSON.parse(saved) : false;
    });
  
    setTimeout(() => {
      localStorage.setItem(lsKey, true);		
    }, 5000);
  return !keyValue && (
    <div className="pill-notification">
      {children}
    </div>
  );
};

export default NotificationPill;