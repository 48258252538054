// Update Payment Details, Pay Now

// ENV Config
import config from '../../config';
import httpRequest from '../../util/http';

// Base Host for API calls
const apiHost = config.API_HOST;

//AbortManager - prevent duplicate update payment
var updatePaymentAbortController = null;


export const apiGetPaymentAuth = async (payload, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/updatepaymentdetails/ValidateAndGetTokens';
	const body = JSON.stringify(payload);

	const response = await httpRequest(url, {
		method: 'POST',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		},
		body: body
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

export const apiUpdatePaymentDetails = async (payload, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/updatepaymentdetails/ProcessAfterRedirect';
	if(updatePaymentAbortController !== null){
		updatePaymentAbortController.abort();
	}
	updatePaymentAbortController = new AbortController();
	const response = await httpRequest(url, {
		method: 'POST',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload),
		signal: updatePaymentAbortController.signal
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

// Retrieve list of outstanding payments
export const apiGetPayNowPaymentControl = async (args, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/PayNow/getpaymentcontrol';

	const params = '?clientPolicyNumber=' + args.clientPolicyNumber
		+ '&ClientPortalLoginId=' + args.ClientPortalLoginId
	
	const response = await httpRequest(url + params, {
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		}
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

export const apiFinalisePayNowTransaction = async (params, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/PayNow/completecollection';

	const response = await httpRequest(url, {
		method: 'POST',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(params)
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}
