
// format to e.g. $2,500
export const formatMoney = (amount) => {
	const f = new Intl.NumberFormat('en-US', {});
	return '$' + f.format(amount);
};

// format to e.g. $2,500.00
export const formatMoneyWithDecimal = (amount) => {
	const f = new Intl.NumberFormat('en-US', {
		//Disabled because it prepends A$ not simply $
		// style: 'currency',
		// currency: 'AUD'
		minimumFractionDigits: 2
	});
	return '$' + f.format(amount);
};


// format a date to e.g. 21/09/2022
export const formatDate = (date, region = "en-AU") => {
	let dateObj = null;
	if(date.includes("/")) {
		const [day, month, year] = date.split('/');	
		dateObj = new Date(Date.UTC(year, month - 1, day));
	} 
	else if(!date.includes("T")){
		dateObj = new Date(Date.UTC(...date.split('-').map((val, index) => index === 1 ? val - 1 : val)));
	}	
	else{
		var splitDate = date.split('T')[0];
		dateObj = new Date(Date.UTC(...splitDate.split('-').map((val, index) => index === 1 ? val - 1 : val)));		
	}
	return Intl.DateTimeFormat(region, { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000));
};

// re-format an address provided by the AusPost backend
export const formatAddress = (addr) => {
    let addressLine = '';
    if (addr['flatOrUnitType'] === 'U') {
        addressLine = addr['flatOrUnitType'] + ' ' + addr['flatOrUnitNumber'] + '/';
    }
    if (addr['postalType'] !== '') {
        addressLine = addressLine + addr['postalType'] + ' ' + addr['postalNumber'];
    }
    else {
        addressLine = addressLine + addr['streetNumber1'];
        if (addr['streetNumber1Suffix'] !== '') {
            addressLine = addressLine + addr['streetNumber1Suffix'];
        }
        if (addr['streetNumber2'] !== '') {
            addressLine = addressLine + '-' + addr['streetNumber2'];
        }
        if (addr['streetNumber2Suffix'] !== '') {
            addressLine = addressLine + addr['streetNumber2Suffix'];
        }
        addressLine = addressLine + ' ' + addr['streetName'] + ' ' + addr['streetType'];
    }
	return {
		AddressLine: toTitleCase(addressLine),
		Suburb: toTitleCase(addr['localityName']),
		State: addr['state'],
		PostCode: addr['postcode'],
		City: null
	};
};

// re-format an address provided by the Google Address Lookup backend (Canada)
export const formatAddressCA = (addr) => {
	return {
		AddressLine: toTitleCase(addr.addressline),
		Suburb: toTitleCase(addr.suburb),
		State: addr.state.toUpperCase(),
		PostCode: addr.postcode.toUpperCase(),
		City: null
	};
};

export const formatISODate = (date) => {
	if(date.includes('/')){
		const [day, month, year] = date.split('/');
		return [year, month, day].join('-');
	}
	return date;
}

export const parseNumber = (str) => {
	return str.replace(/[^\d]/g, '');
}

// Note: Currently not in use
export const addNumberSpacing = (spacingPosition, maxLength, string, separator) => {
	const numberStr = parseNumber(string);
	const arr = numberStr.split('');
	const formattedArray = arr.map((char, index) => {
		const position = index + 1;
		if(position % spacingPosition === 0 && position < maxLength){
			char = char + separator;
		}

		return char;
	});
	return formattedArray.join("");
}

// Note: Currently not in use
export const deleteNumberSpacing = (spacingPosition, maxLength, separator, e) =>{
	if(e.which === 8){
		var range = e.target.selectionStart
		if(range > spacingPosition){
			var targetChar = e.target.value.substring(range - 1, range);
			if(targetChar === separator){
				let str1 = e.target.value.substring(0, range - 1);
				let str2 = range < e.target.value.substring.length ? e.target.value.substring(range + 1, e.target.value.substring.length) : '';
				e.target.value = str1 + str2;
			}
		}
		
	} else if(e.target.value.length >= maxLength && e.which !== 9) {
		e.preventDefault();
	}
}

export const toTitleCase = (str) => {
	if(typeof str === 'string' && str.length > 0){
		return str.toLowerCase().split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' ');
	} else {
		return str;
	}
}
