// Policy Lookup

// ENV Config
import config from '../../config';
import httpRequest from '../../util/http';

// Base Host for API calls
const apiHost = config.API_HOST;

export const apiGetPolicyDetails = async (PolicyNumber, ClientPortalLoginId, token, rejectWithValue) => {
	const url = apiHost + '/api/v1/Policy/policydetail';

	const payload = {
		PolicyNumber: PolicyNumber,
		ClientPortalLoginId: ClientPortalLoginId
	};

	const response = await httpRequest(url, {
		method: 'POST',
		headers: { 
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

// Retrieve list of outstanding payments
export const apiGetPolicyOutstandingPayments = async (args, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/PayNow/getrealtimepayments';
	
	const params = '?clientPolicyNumber=' + args.clientPolicyNumber
		+ '&ClientPortalLoginId=' + args.ClientPortalLoginId
	
	const response = await httpRequest(url + params, {
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		}
	});

	const policyNumber = String(args.clientPolicyNumber);

	if (response.status === 204) {
		// policy has no transactions pending
		return { 
			Payments: [],
			updatePolicyStatus: true,
			clearPolicyPayNowPending: true,
			policyNumber: policyNumber
		};
	}
	else if (response.status === 467) {
		// no transactions pending, should not see this as api gateway converts to 204
		return { 
			Payments: [],
			updatePolicyStatus: true,
			policyNumber: policyNumber
		};
	}
	else if (response.status === 473) {
		// transaction is running in the background
		return { 
			Payments: [],
			markPolicyPayNowPending: true,
			policyNumber: policyNumber
		};
	}
	else if (response.status === 469) {
		// transaction is "in flight"
		// there is a retry occurring, usually direct debit
		// business said not to report anything to user about these
		return {
			Payments: []
		};
	}
	else if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return {
			Payments: data.Payments,
			updatePolicyStatus: true,
			clearPolicyPayNowPending: true,
			policyNumber: policyNumber
		}
	}
}
