export const isConfigLoadedSelector = (state) => state.config.configLoaded ?? false;
export const recaptchaFeatureEnabledSelector = (state) => state.config.featureFlag.recaptchaFeature ?? false;
export const emailAuthenticationFeatureSelector = (state) => state.config.featureFlag.emailAuthFeature ?? false;
export const documentsFeatureEnabledSelector = (state) => state.config.featureFlag.documentsFeature ?? false;
export const postcodeVerificationFeatureEnabledSelector = (state) => state.config.featureFlag.postcodeVerificationFeature ?? false;
export const hasExtendedDishonourSelector = (state) => state.config.featureFlag.extendedDishonourPolicies ?? false;

export const regionalisationSelector = (state) => state.config.regionalisation ?? {};
export const policyPageConfigSelector = (state) => state.config.regionalisation.pages.policyDetails ?? {};
export const payNowPageConfigSelector = (state) => state.config.regionalisation.pages.payNow ?? {};
export const updatePaymentDetailsPageConfigSelector = (state) => state.config.regionalisation.pages.updatePaymentDetails ?? {};
export const updateContactDetailsPageConfigSelector = (state) => state.config.regionalisation.pages.updateContactDetails ?? {};

export const shouldSwapPhoneFieldsSelector = (state) => state.config.regionalisation.pages.updateContactDetails?.swapPhoneNumberFields ?? false;
export const isEmailRequiredFieldSelector = (state) => state.config.regionalisation.pages.updateContactDetails?.isEmailRequired ?? false;
export const paymentsParameterSelector = (state) => state.config.regionalisation.payments ?? {};
export const dateFormatRegionSelector = (state) => state.config.regionalisation.dates.region;
