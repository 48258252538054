// Externals
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Selectors
import { documentListCookieSelector, documentTypeSelector } from '../../store/selectors/documents';

// Components
import SpinnerOverlay from '../Widgets/SpinnerOverlay';
import ViewEmail from './ViewEmail';

// Styles
import './View.scss';

// ENV Config
import config from '../../config';
import httpRequest from '../../util/http';

import { convertPdfBase64ToLink, isBrowserHasPDFViewer } from '../../util/fileHandler'

// Base Host for API calls
const apiHost = config.API_HOST;

// View Document
const DocumentView = (props) => {
	const { policyNumber } = useParams();
	const [queryParams] = useSearchParams();
	const bearerToken = useSelector(state => state.auth.bearerToken);
	const documentType = useSelector(state => documentTypeSelector(state, policyNumber, queryParams.get("docId")));
	const docId = queryParams.get("docId");
	const cookies = useSelector(state => documentListCookieSelector(state, policyNumber, (docId ? docId : "").split(" ").join("+")));	
	const documentUrl = `${apiHost}/api/v1/Document/documentstream`;
	const [ document, setDocument ] = useState(null);
	const [ downloadError, setDowloadError ] = useState(false);

	//AUPortals TODO: Document retrievals are geared toward quadient only. May need to have views and actions for each provider or something. Disabled in AUS
	//ATM so can ignore for now.
	useEffect(() => {
		setDowloadError(false);
		if (queryParams.get("docId") && document === null) {	
			const body = JSON.stringify({
				DocumentId: docId.split(" ").join("+"),
				QuadientHeaders: cookies
			});		
			console.log(body);
			httpRequest(documentUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: 'Bearer ' + bearerToken,									
				},
				body: body
			})
			.then(response => response.text())
			.then(base64 => {				
				base64 = base64.replace(/^"(.+)"$/, '$1').replace(/(\r\n|\n|\r)/gm, '');
				if (documentType === 'pdf') {
					//loading pdf in iframe was causing issue in IOS devices like iPhone and iPad
					// where only first poage was loading. We changed it to blob link
					const url = convertPdfBase64ToLink(base64);
					window.location.href = url;
					//For browsers which doesnt support pdfviewer, file is downloaded
					//We need to close the loading broswer tab
					if(!isBrowserHasPDFViewer()){
						setDocument('');
						setTimeout("window.close()", 2000);
					}
				}
				if (documentType === 'eml') {
					setDocument(window.atob(base64));
				}
			})
			.catch(err => {
				console.log(err);
				setDowloadError(true);
			});
		}
	}, [ queryParams, documentUrl, document, documentType, bearerToken ]);
	
	if (documentType === null || cookies === null) return <p>Document not found</p>;
	if (downloadError) return <p>Unable to download the Document. Please try again.</p>;
	if (!document) return <SpinnerOverlay message='Loading your document...' />;
	// loading pdf in iframe was causing issue in IOS devices like iPhone and iPad
	// if (document && documentType === 'pdf') return (
	// 	<iframe 
	// 		className='document-view-pdf' 
	// 		title='document' 
	// 		src={'data:application/pdf;base64,' + document} 
	// 	/>
	// );
	else if (document && documentType === 'eml') return (
		<ViewEmail>{document}</ViewEmail>
	)
};

// Export
export default DocumentView;
