// Externals
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// Selectors
import { headingSelector } from '../store/selectors/brand';
import { isConfigLoadedSelector } from '../store/selectors/config';

// Forms
import FormLogin from '../components/Forms/Login';

// Components
import Container from '../components/Layout/Container';
import { loggedInSelector } from '../store/selectors/auth';
import Spinner from '../components/Widgets/Spinner';

// Styles
import './Login.scss';

// Login container
const Login = () => {
	const navigate = useNavigate();
	const { search } = useLocation();
	const loggedIn = useSelector(loggedInSelector);
	const pageHeading = useSelector(state => headingSelector(state, 'myAccountLogin'));
	const isConfigLoaded = useSelector(isConfigLoadedSelector);

	useEffect(() => {
		if (loggedIn) {
			navigate(`/${search}`);
		}
	}, [ loggedIn, navigate ]);

	return (
		<div className='page-login contacts-container'>
			<div className='login-background-overlay'></div>
			<Container>

				<div className='login-box'>
					<h3>{pageHeading}</h3>
					{isConfigLoaded && 
						<FormLogin />
					}
					{!isConfigLoaded &&
						<Spinner />
					}
				</div>
			
			</Container>
			<br style={{ clear: 'both' }} />
		</div>
	);
};
export default Login;
