import { createSlice } from '@reduxjs/toolkit';
import { getBrandConfiguration } from '../actions/config';

const initialState = {
  configLoaded: false,
  featureFlag: {
    recaptchaFeature: false, // protect login with recaptcha
    documentsFeature: null, //1 - Quadient, null - None
    postcodeVerificationFeature: false, //verify postcode using address api
    emailAuthFeature: false, //enable email authentication
    displayPaymentFrequency: false, //display payment frequency
    extendedDishonourPolicies: false //display extended dishonour policies
  },
  regionalisation: {    
  },
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBrandConfiguration.pending, (state, action) => {
      //
    });  

    builder.addCase(getBrandConfiguration.rejected, (state, action) => {
      //      
    });

    builder.addCase(getBrandConfiguration.fulfilled, (state, action) => {      
      state.configLoaded = true;
      state.featureFlag = action.payload.featureFlag;
      state.regionalisation = action.payload.regionalisation;
    });
  }
});

export default configSlice.reducer;