// Externals
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Selectors
import { contactUpdateInProgress, 
	editContactSelector, 
	updateContactErrorsSelector,
	addressLookupSelector,
	postalAddressLookupSelector } from '../../store/selectors/contact';

import { contactUpdateFailureMessageSelector, editContactDetailsTermsSelector } from '../../store/selectors/brand';
import { regionalisationSelector, postcodeVerificationFeatureEnabledSelector, updateContactDetailsPageConfigSelector, shouldSwapPhoneFieldsSelector, isEmailRequiredFieldSelector } from '../../store/selectors/config';

//AUPortals = resetPendingResidentialUpdate,resetPendingPostalUpdate are not present in au version
//these are just used to reset to current address when switching to / from manual mode. 

// Actions
import { 
	updateContactField,
	updateCustomerContacts,
	updateContactCopyResidentialToPostal,
	resetPendingUpdate, 
	resetPendingResidentialUpdate,
	resetPendingPostalUpdate} from '../../store/actions/contact';

import { resetPostCodeMatches } from '../../store/actions/address';

// Form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';


// Components
import AddressLookup from './AddressLookup';
import FlashMessage from '../Widgets/FlashMessage';
import SpinnerOverlay from '../Widgets/SpinnerOverlay';

// Constants
import config from '../../config';

// Styles
import './ContactDetails.scss';

// Utility
import { getUpdateContactValidationSchema, preventNonNumericInput } from '../../util/validate';
import { tokenSelector } from '../../store/selectors/auth';


const defaultCountryId = config.DEFAULT_COUNTRY_ID;

// Contact Details Form
const ContactDetails = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const region = useSelector(regionalisationSelector);	
	const postcodeVerificationEnabled = useSelector(postcodeVerificationFeatureEnabledSelector);

	const formRef = useRef();
	const pageConfig = useSelector(updateContactDetailsPageConfigSelector);
	const contact = useSelector(editContactSelector);
	const editContactDetailsTermsHtml = useSelector(editContactDetailsTermsSelector);
	const updateContactFailureMessage = useSelector(contactUpdateFailureMessageSelector);
	const showError = useSelector(updateContactErrorsSelector);
	const spinner = useSelector(contactUpdateInProgress);
	const residentialAddress = useSelector(addressLookupSelector);
	const postalAddress = useSelector(postalAddressLookupSelector);		
	const shouldSwapPhoneFields = useSelector(shouldSwapPhoneFieldsSelector);
	const isEmailRequiredField = useSelector(isEmailRequiredFieldSelector);
	
	const token = useSelector(tokenSelector);
	const validationSchema = getUpdateContactValidationSchema(region, postcodeVerificationEnabled, shouldSwapPhoneFields, isEmailRequiredField, token);

	const [ manualAddressMode, setManualAddressMode ] = useState(false);
	const [ manualPostalAddressMode, setManualPostalAddressMode ] = useState(false);	
	
	const [ customPostalAddress, setCustomPostalAddress ] = useState(!contact.postalSame);

	const { register, handleSubmit, formState: { errors, isSubmitted, validatingFields, isSubmitting} } = useForm({
		values: contact,
		resetOptions: {
			keepIsSubmitted: true,
			keepIsValid: true,
			keepErrors: true,
		},
		resolver: yupResolver(validationSchema),
		shouldFocusError: true		
	});

	const getStatusClass = (fieldErrors, isSubmitted) => {
		return isSubmitted ? 
			(fieldErrors ? ' invalid' : ' valid') : '';

	}

	// upon unmount, clear changes to pending contact update
	useEffect(() => {
		return () => {
			dispatch(resetPendingUpdate());
			dispatch(resetPostCodeMatches());
		}
	}, [ dispatch ]);

	useEffect(()=>{
		if(showError){
			handleScrollToError();
		}
	}, [showError]);

	const handleToggleAddressMode = () => {
		if(manualAddressMode){
			dispatch(resetPendingResidentialUpdate());
		}
		setManualAddressMode(!manualAddressMode);
	};

	const handleTogglePostalAddressMode = () => {
		if(manualPostalAddressMode){
			dispatch(resetPendingPostalUpdate());
		}
		setManualPostalAddressMode(!manualPostalAddressMode);
	}

	const handleToggleCustomPostalAddress = () => {
		if (customPostalAddress === true) {
			dispatch(updateContactCopyResidentialToPostal());
		}
		setCustomPostalAddress(!customPostalAddress);
	};

	const handleUpdateField = (e) => {
		var name = e.target.name;
		var val  = e.target.name === "ResidentialAddress.PostCode" || e.target.name === "PostalAddress.PostCode" ? e.target.value.toUpperCase() : e.target.value;
		//Merge TODO: Refactor postcode formatting for country agnostic approach
		if((e.target.name === "ResidentialAddress.PostCode" || e.target.name === "PostalAddress.PostCode") && val.replace(/\s/g).length === 6){
			val = val.substring(0, 3) + " " + val.substring(3, 6);
		}
	
		dispatch(updateContactField({ name: name, value: val }));
	};

	const handleNumericOnlyInput = (e) => {
		if(e.key !== '-'){
			preventNonNumericInput(e)
		}
	}

	const handleScrollToError = () => {
		const errorMessage = formRef.current.querySelector(".flash-message");
		if(errorMessage !== null) errorMessage.scrollIntoView();
	}

	const newHandleSubmit = (data) => {
		dispatch(updateCustomerContacts());
	}
	
	const handleCancel = () => {
		navigate('/contacts');
	}

	// Render
	return (
		<form onSubmit={handleSubmit(newHandleSubmit)}>
			<div className='form form-contact-details' ref={formRef}>
				{ showError && (
					<FlashMessage message={updateContactFailureMessage} error={true} setHtml={true}/>
				)}
					
				{ manualAddressMode === false && (<>
				
					<div className={'field field-contact-address-lookup' + getStatusClass(errors.ResidentialAddress?.AddressLine || errors.ResidentialAddress?.Suburb || errors.ResidentialAddress?.PostCode, isSubmitted)}>

						<label htmlFor='addressLookup'>{region.address.residential.label}</label>

					{ //TODO: classname prop 
					}
					<AddressLookup address={residentialAddress} isResidential={true} matchPostalAddress={!customPostalAddress} />
				
					</div>

					<button className='btn-link address-mode-button' onClick={handleToggleAddressMode}>{region.address.residential.enableManualAddressEntryText}</button>
				
				</>)}

				{ manualAddressMode === true && (<>
				
					<div className={'field field-contact-address' + getStatusClass(errors.ResidentialAddress?.AddressLine, isSubmitted)}>
						<label htmlFor='ResidentialAddress.AddressLine'>{region.address.residential.label}</label>
						<input 
							className='dd-privacy-mask' 
							placeholder={region.address.residential.placeholder} 
							{
								...register(	
									"ResidentialAddress.AddressLine", 
									{ 																		
										value: contact.ResidentialAddress.AddressLine, 
										onChange:handleUpdateField, 																
									}								
								)
						} 
						/>
						{errors.ResidentialAddress?.AddressLine && <div className='invalid-message'>{region.address.residential.invalidMessage}</div>}
					</div>

					<div className={'field field-contact-suburb' + getStatusClass(errors.ResidentialAddress?.Suburb, isSubmitted)}>
						<label htmlFor='ResidentialAddress.Suburb'>{region.address.suburb.label}</label>
						<input 			
							className='dd-privacy-mask' 				
							placeholder={region.address.suburb.placeholder} 
							{
								...register(	
									"ResidentialAddress.Suburb", 
									{ 																																
										value: contact.ResidentialAddress.Suburb, 
										onChange:handleUpdateField, 															
									}								
								)
						} 
						/>
						{errors.ResidentialAddress?.Suburb && <div className='invalid-message'>{region.address.suburb.invalidMessage}</div> }
					</div>

					<div className='field field-contact-state'>
						<label htmlFor='state'>{region.address.state.label}</label>
						<select 
							className='dd-privacy-mask'
							{
								...register(
									"ResidentialAddress.State", 
									{																										
										value: contact.ResidentialAddress.State, 
										onChange:handleUpdateField, 																	
									}								
								)
							} 
						>
							{Object.keys(region.address.states).map((stateAbbrev, index) => (
								<option key={index} value={stateAbbrev}>{region.address.states[stateAbbrev]}</option>
							))}
						</select>
					</div>

					<div className={'field field-contact-postcode' + getStatusClass(errors.ResidentialAddress?.PostCode, isSubmitted)}>
						<label htmlFor='ResidentialAddress.PostCode'>{region.address.postCode.label}</label>
						<input 
							className='dd-privacy-mask' 							
							inputMode={region.address.postCode.inputMode} 
							placeholder={region.address.postCode.placeholder} 							
							maxLength={region.address.postCode.maxLength} 
							onKeyDown={region.address.postCode.inputMode === 'numeric' ? handleNumericOnlyInput : null}
							{
								...register(	
									"ResidentialAddress.PostCode", 
									{ 																		
										value: contact.ResidentialAddress.PostCode, 
										onChange:handleUpdateField, 																				
									}								
								)
						} 
						/>
						{errors.ResidentialAddress?.PostCode && <div className='invalid-message'>{region.address.postCode.invalidMessage}</div>}
					</div>

					<button className='btn-link address-mode-button' onClick={handleToggleAddressMode}>{region.address.residential.disableManualAddressEntryText}</button>

				</>)}
				
				<div className='field field-contact-postal-address-container'>

					<label htmlFor='postal-toggle'>{region.address.postal.label}</label>

					<div className='field field-contact-postal-toggle'>
						<input type='checkbox' checked={!customPostalAddress} onChange={handleToggleCustomPostalAddress} />
						<span>{region.address.postal.sameAsResidentialLabel}</span>
					</div>

					{ manualPostalAddressMode === false && customPostalAddress === true && <>
						<div className={'field field-contact-postal-address-lookup' + getStatusClass(errors.PostalAddress?.AddressLine || errors.PostalAddress?.Suburb || errors.PostalAddress?.PostCode, isSubmitted)}>
							<AddressLookup className={'postal-address-lookup'} address={postalAddress} isResidential={false} />
							<button className='btn-link address-mode-button postal-address-mode-button' onClick={handleTogglePostalAddressMode}>{region.address.postal.enableManualAddressEntryText}</button>
						</div>
					</> }

					{ manualPostalAddressMode === true && customPostalAddress === true && (<>
					
						{/* <div className='field field-country-selection'>
							<label htmlFor='PostalAddress.CountryId'>Country</label>
							<select name="PostalAddress.CountryId" value={contact.PostalAddress.CountryId} onChange={handleUpdateField}>
								{Object.keys(countries).map((countryId) => (
									<option value={countryId} key={countryId}>{countries[countryId]}</option>
								)) }
							</select>
						</div> */}

						<div className={'field field-contact-postal-address' + getStatusClass(errors.PostalAddress?.AddressLine, isSubmitted)}>
							<label htmlFor='PostalAddress.AddressLine'>{region.address.postal.label}</label>
							<input
								placeholder={region.address.postal.placeholder}
								{
									...register(
										"PostalAddress.AddressLine", 
										{																										
											value: contact.PostalAddress.AddressLine, 
											onChange: handleUpdateField, 																	
										}								
									)
								}
							/>
							{errors.PostalAddress?.AddressLine && <div className='invalid-message'>{region.address.postal.invalidMessage}</div> }
						</div>

						<div className={'field field-contact-postal-suburb' + getStatusClass(errors.PostalAddress?.Suburb, isSubmitted)}>
							<label htmlFor='PostalAddress.Suburb'>{region.address.suburb.label}</label>
							<input 
								className='dd-privacy-mask'
								placeholder={region.address.suburb.placeholder} 								 																
								{
									...register(
										"PostalAddress.Suburb", 
										{																										
											value: contact.PostalAddress.Suburb, 
											onChange: handleUpdateField, 																	
										}								
									)
								} 
							/>
							{errors.PostalAddress?.Suburb && <div className='invalid-message'>{region.address.suburb.invalidMessage}</div> }
						</div>

						<div className='field field-contact-postal-state'>
							<label htmlFor='field field-contact-postal-state'>{region.address.state.label}</label>
							{/* {contact.PostalAddress.CountryId  === defaultCountryId && */}
								<select 								
									className='dd-privacy-mask'						
									{
										...register(
											"PostalAddress.State", 
											{																										
												value: contact.PostalAddress.State, 
												onChange: handleUpdateField, 																	
											}								
										)
									}
								>
									{Object.keys(region.address.states).map((stateAbbrev, index) => (
										<option key={index} value={stateAbbrev}>{region.address.states[stateAbbrev]}</option>
									))}
								</select>
							{/* } */}

							{/* {contact.PostalAddress.CountryId  !== defaultCountryId && 
								<input name={'PostalAddress.State'} value={contact.PostalAddress.State} onChange={handleUpdateField}/>
							} */}
						</div>

						<div className={'field field-contact-postal-postcode' + getStatusClass(errors.PostalAddress?.PostCode, isSubmitted)}>
							<label htmlFor='PostalAddress.PostCode'>{region.address.postCode.label}</label>
							{/* {contact.PostalAddress.CountryId === defaultCountryId && */}
								<>
									<input 
										name='PostalAddress.PostCode' 
										className='dd-privacy-mask'
										inputMode={region.address.postCode.inputMode} 
										placeholder={region.address.postCode.placeholder} 
										maxLength={region.address.postCode.maxLength} 
										onKeyDown={region.address.postCode.inputMode === 'numeric' ? handleNumericOnlyInput : null}																											
										{
											...register(
												"PostalAddress.PostCode", 
												{																										
													value: contact.PostalAddress.PostCode, 
													onChange:handleUpdateField																									
												}								
											)
										}
									/>
									{errors.PostalAddress?.PostCode && <div className='invalid-message'>{region.address.postCode.invalidMessage}</div>}
								</>
							{/* } */}

							{/* {contact.PostalAddress.CountryId !== defaultCountryId && 
								<input name='PostalAddress.PostCode' inputMode='numeric' placeholder='Postal Code' value={contact.PostalAddress.PostCode} onChange={handleUpdateField} />
							} */}
						</div>

						<button className='btn-link address-mode-button' onClick={handleTogglePostalAddressMode}>{region.address.postal.disableManualAddressEntryText}</button>
					</>)}
				</div>
				

				<div className={'field field-contact-mobile' + getStatusClass(shouldSwapPhoneFields ? errors.HomePhone : errors.MobilePhone, isSubmitted)}>
					<label htmlFor='MobilePhone'>{region.phone.mobile.label}</label>
					<input 
						inputMode='numeric'
						className='dd-privacy-mask' 
						placeholder={shouldSwapPhoneFields ? region.phone.home.placeholder : region.phone.mobile.placeholder} 						
						maxLength={shouldSwapPhoneFields ? region.phone.home.maxLength : region.phone.mobile.maxLength} 						
						onKeyDown={handleNumericOnlyInput} 						
						{
							...register(	
								shouldSwapPhoneFields ?  "HomePhone" : "MobilePhone", 
								{ 																																															
									value: shouldSwapPhoneFields ? contact.HomePhone : contact.MobilePhone, 
									onChange:handleUpdateField																				
								}								
							)
						} 
					/>
					{((!shouldSwapPhoneFields && errors.MobilePhone) || (shouldSwapPhoneFields && errors.HomePhone)) && <div className='invalid-message'>{region.phone.mobile.invalidMessage}</div> }
				</div>

				<div className={'field field-contact-landline' + getStatusClass(shouldSwapPhoneFields ? errors.MobilePhone : errors.HomePhone, isSubmitted)}>
					<label htmlFor='HomePhone'>{region.phone.home.label}</label>
					<input 						
						inputMode='numeric' 
						className='dd-privacy-mask'
						placeholder={shouldSwapPhoneFields ? region.phone.mobile.placeholder : region.phone.home.placeholder} 							
						onKeyDown={handleNumericOnlyInput} 						
						{		
							...register(	
								shouldSwapPhoneFields ? "MobilePhone" : "HomePhone", 								
								{ 																																
									value: shouldSwapPhoneFields ? contact.MobilePhone : contact.HomePhone, 								
									onChange:handleUpdateField 																	
								}								
							)
						}
					/>
					{((!shouldSwapPhoneFields && errors.HomePhone) || (shouldSwapPhoneFields && errors.MobilePhone)) && <div className='invalid-message'>{region.phone.home.invalidMessage}</div> }
				</div>

				<div className={'field field-contact-email' + getStatusClass(errors.Email, isSubmitted)}>
					<label htmlFor='Email'>{region.email.label}</label>
					<input 						
						inputMode='email' 
						className='dd-privacy-mask' 
						placeholder={region.email.placeholder} 
						{		
							...register(	
								"Email", 								
								{ 																		
									value: contact.Email, 
									onChange:handleUpdateField,												
								}								
							)
						}
					/>
					{errors.Email && <div className='invalid-message'>{region.email.invalidMessage}</div>}
				</div>

				<p className='terms' dangerouslySetInnerHTML={{ __html: editContactDetailsTermsHtml }}></p>

				<button className='btn form-btn btn-rounded submit' type="submit">{pageConfig.saveContactDetailsButtonText}<span className='chevron-r'></span></button>

				<button className='btn form-btn cancel secondary btn-rounded submit' onClick={handleCancel}>Cancel</button>

				<br /><br />

				{ (spinner || (validatingFields && isSubmitting)) && <SpinnerOverlay message='Updating your contact information...'/> }

			</div>
		</form>
	);
};

// Exports
export default ContactDetails;
