import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { chequeMethodChangeCTASelector } from '../../../store/selectors/brand';

// Selectors
import { headingSelector } from '../../../store/selectors/brand';
import { dateFormatRegionSelector, policyPageConfigSelector } from '../../../store/selectors/config';

// Util
import { formatDate, formatMoneyWithDecimal } from '../../../util/formats';

// Components
import Table from '../../Layout/Table';
import TableRow from '../../Layout/TableRow';

// Assets 
import { ReactComponent as EditIcon } from '../../../static/pencil-solid.svg'; 


const PaymentDetails = ({policy}) => {
    const installment = calculateInstallments(policy);
    const chequeMethodChangeMessage = useSelector(chequeMethodChangeCTASelector);
	const sectionHeading = useSelector(state => headingSelector(state, 'paymentDetails'));        
    const pageConfig = useSelector(policyPageConfigSelector);        
    const dateFormatRegion = useSelector(dateFormatRegionSelector);
    return (
        <>
           <div className='payment-detail-header detail-heading'>
               <h3>{sectionHeading}</h3> 
               { policy.PaymentMethod.PaymentMethodId !== 3 &&
                <span className='edit-link-container'>
                    <Link className='edit-link' to={'/update/payment/' + policy.PolicySummary.PolicyNumber}><EditIcon />Edit details</Link>
                </span>
               }
            </div>
           <Table className={'payment-details'}>
                <TableRow heading={pageConfig.policyInstallmentLabel} value={formatMoneyWithDecimal(installment)}/>
                {pageConfig.paymentDetails.displayPaymentFrequency && <TableRow heading={'Payment Frequency'} value={policy.PaymentFrequency.Description}/> }
                <TableRow heading={'Next Payment Date'} value={formatDate(policy.PolicySummary.NextCollectionDate, dateFormatRegion)}/>
                <TableRow heading={'Payment Method'} value={policy.PaymentMethod.Description}/>

                {policy.PaymentMethod.PaymentMethodId === 1 && //Credit Card
                    <>
                        {policy.PolicySummary.CreditCardNumberSuffix && 
                            <TableRow className={'credit-card-row'} valueClassName='dd-privacy-mask' heading={'Payment Card Number'} value={'**** **** **** *' + policy.PolicySummary.CreditCardNumberSuffix}/>
                        }

                        { 
                            policy.PolicySummary.CreditCardNumber && <TableRow className={'credit-card-row'} valueClassName='dd-privacy-mask' heading={'Payment Card Number'} value={policy.PolicySummary.CreditCardNumber}/>
                        }
                        <TableRow valueClassName='dd-privacy-mask' heading={'Expiry Date'} value={(policy.PolicySummary.CCExpiry ?? '').replace('-', '/')}/>
                    </> 
                }

                {policy.PaymentMethod.PaymentMethodId === 2 && //Direct Debit
                    <>
                        <TableRow valueClassName='dd-privacy-mask' heading={'Account Name'} value={policy.PolicySummary.BankAccountName}/>   
                        { 
                            pageConfig.paymentDetails.displayBsb && 
                            <TableRow valueClassName='dd-privacy-mask' heading={'BSB'} value={policy.PolicySummary.BankAccountBsb}/>                             
                        }
                        <TableRow valueClassName='dd-privacy-mask' heading={'Account Number'} value={policy.PolicySummary.BankAccountNumber}/>
                    </>
                }

            </Table> 

            { policy.PaymentMethod.PaymentMethodId === 3 && //Cheque
                <p className='payment-method-change-msg' dangerouslySetInnerHTML={{ __html: chequeMethodChangeMessage}}></p>
            }
        </>
    )
}

const calculateInstallments = (policy) => {
    let amount = 0;
    policy.LifeInsured.forEach( lifeInsured => lifeInsured.BenefitDetails.forEach(detail => amount += detail.FrequencyPremiumAmount));

    return amount;
}

export default PaymentDetails
