// Strings
import { FiftyUpBrandHeadings, FiftyUpBrandMessages, FiftyUpBrandPhone } from './FiftyUp/strings/BrandMessages';
import { NorthCoverBrandHeadings, NorthCoverBrandMessages, NorthCoverBrandPhone } from './NorthCover/strings/BrandMessages';
import { SeniorsBrandHeadings, SeniorsBrandMessages, SeniorsBrandPhone } from './AustralianSeniors/strings/BrandMessages';
import { RealBrandHeadings, RealBrandMessages, RealBrandPhone } from './RealInsurance/strings/BrandMessages';

// Logos
import FiftyUpLogo from './FiftyUp/static/FiftyUpLogo.svg';
import NorthCoverLogo from './NorthCover/static/NorthCoverLogo.svg';
import RealLogo from './RealInsurance/static/RealInsurance.svg';
import SeniorsLogo from './AustralianSeniors/static/logo-auseniors.svg';

const setBrandHeadings = (brand) => {
    switch (brand) {
        case 'fiftyup': 
            return FiftyUpBrandHeadings;
		case 'northcover':
			return NorthCoverBrandHeadings;
        case 'seniors':
            return SeniorsBrandHeadings;
        case 'real':
            return RealBrandHeadings;
        default:
            return RealBrandHeadings;
    }
}

const setBrandMessages = (brand) => {
    switch (brand) {
        case 'fiftyup': 
            return FiftyUpBrandMessages;
		case 'northcover':
			return NorthCoverBrandMessages;
        case 'seniors':
            return SeniorsBrandMessages;
        case 'real':
            return RealBrandMessages;
        default:
            return RealBrandMessages;
    }
}

const setBrandPhone = (brand) => {
    switch (brand) {
        case 'fiftyup': 
            return FiftyUpBrandPhone;
		case 'northcover':
			return NorthCoverBrandPhone;
        case 'seniors':
            return SeniorsBrandPhone;
        case 'real':
            return RealBrandPhone;
        default: 
            return RealBrandPhone;
    }
}

const setBrandLogo = (brand) => {
    switch (brand) {
        case 'fiftyup':
            return FiftyUpLogo;
		case 'northcover':
			return NorthCoverLogo;
        case 'seniors':
            return SeniorsLogo;
        case 'real':
            return RealLogo;
        default:
            return RealLogo;
    }
}

const createBrandState = (brand) => {
    return {
		brandHeadings: setBrandHeadings(brand),
        brandMessages: setBrandMessages(brand),
        brandPhone: setBrandPhone(brand),
        brandLogo: setBrandLogo(brand)
    }
}

const customisations = {
    fiftyup: createBrandState('fiftyup'),
	northcover: createBrandState('northcover'),
    seniors: createBrandState('seniors'),
    real: createBrandState('real')
}

export default customisations;
