// Externals
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

// Helpers for fetching async data
import { apiFormatAddress, apiLookupAddress, apiLookupPostcode } from '../async/address';

// Fetch a list of matching addreses
export const getAddressMatches = createAsyncThunk('address/GET_ADDRESS_MATCHES', 	
	async ({addressText, isResidential}, { getState, rejectWithValue }) => {
		const token = getState().auth.bearerToken;
		return await apiLookupAddress(addressText, isResidential, token, rejectWithValue);
	}
);

export const getPostcodeMatches = createAsyncThunk('address/GET_POSTCODE_MATCHES',
	async ({postcode, isResidential}, { getState, rejectWithValue }) => {		
		const token = getState().auth.bearerToken;
		return await apiLookupPostcode(postcode, isResidential, token, rejectWithValue);
	}
);

export const getFormattedAddress = createAsyncThunk('address/GET_FORMATTED_ADDRESS', 
	// TODO: abortController
	async ({address, isResidential, matchPostalAddress}, { getState, rejectWithValue }) => {
		const token = getState().auth.bearerToken;
		return await apiFormatAddress(address, isResidential, matchPostalAddress, token, rejectWithValue);
	}
);

export const resetAddressMatches = createAction('address/RESET_ADDRESS_MATCHES');
export const resetPostCodeMatches = createAction('address/RESET_POSTCODE_MATCHES');
