import { datadogRum } from '@datadog/browser-rum';

// ENV Config
import config from '../config';

export const initAppMonitoring = () => {
    const applicationId = config.DATADOG_APPLICATIONID;
    const clientToken = config.DATADOG_CLIENTTOKEN;
    const serviceName = config.DATADOG_SERVICE_NAME;
    const environment = config.ENVIRONMENT;

    if(applicationId === undefined || clientToken === undefined || serviceName === undefined || environment === undefined) {
        return;
    }    

    if(applicationId === 'notimplemented' ) {
        return;
    }

    datadogRum.init({
        applicationId: applicationId,
        clientToken: clientToken,
        site: 'datadoghq.com',
        service: serviceName,
        env: environment,
        
        // Specify a version number to identify the deployed version of your application in Datadog 
        // We will implement version as part of config rework
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
    });

    datadogRum.startSessionReplayRecording();
}