// Externals
import React, { useEffect } from 'react';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { Route, Routes } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import history from './store/history';

// Selectors
import { loggedInSelector } from './store/selectors/auth';

// Pages
import ContactDetails from './pages/ContactDetails';
import ContactDetailsUpdate from './pages/ContactDetailsUpdate';
import Documents, { DocumentView } from './pages/Documents';
import Error404 from './pages/Error404';
import MakePayment from './pages/MakePayment';
import MyPolicies from './pages/MyPolicies';
import Login from './pages/Login';
import Logout from './pages/Logout';
import UpdatePayment from './pages/UpdatePayment';
import ViewPolicy from './pages/ViewPolicy';

// Auth HOC
import Auth from './Auth';

// Base Styles
import './App.scss';

// Brand themes
import Brand from './brands';

// ENV Config
import config from './config';
import { getBrandConfiguration } from './store/actions/config';
import { documentsFeatureEnabledSelector, recaptchaFeatureEnabledSelector } from './store/selectors/config';


// Google Tag Manager
const gtmParams = {
    id: config.GTM_ID
};

// Activate the configured brand
const brand = config.BRAND;

//retrieve the configuration based on country / brand
const countryId = config.DEFAULT_COUNTRY_ID;
const brandId = config.BRAND_ID;

// App Container
const App = () => {
	
	const loggedIn = useSelector(loggedInSelector);
	const dispatch = useDispatch();

	const documentsFeature = useSelector(documentsFeatureEnabledSelector);
	const recaptchafeature = useSelector(recaptchaFeatureEnabledSelector);
	const hideRecaptchaBadge = "<style> .grecaptcha-badge { display: none!important; }</style>";


	// Browser back/forward cache (bfcache) fails to properly unfreeze our js
	// https://webkit.org/blog/427/webkit-page-cache-i-the-basics/
	useEffect(() => {
		const handlebfCache = (e) => {
			console.log('bfcache', e.persisted)
			if (e.persisted) window.location.reload();
		};
		window.addEventListener('pageshow', handlebfCache);
		dispatch(getBrandConfiguration({ countryId, brandId}))
		return () => window.removeEventListener('pageshow', handlebfCache);
	}, []);

	return (
		<GTMProvider state={gtmParams}>
			{loggedIn && recaptchafeature && <div dangerouslySetInnerHTML={{ __html: hideRecaptchaBadge }}></div>}
			<Router history={history}>
				<Auth>
					<Brand brand={brand}>
						{loggedIn && (
							<Routes>
								<Route path='/' element={<MyPolicies />} />
								<Route path='/policy/:policyNumber' element={<ViewPolicy />} />
								{ documentsFeature !== null && documentsFeature === 1 && <>
									<Route path='/documents/:policyNumber' element={<Documents />} />
									<Route path='/documents/:policyNumber/view' element={<DocumentView />} />
								</> }
								<Route path='/payment/:policyNumber' element={<MakePayment />} />
								<Route path='/update/payment/:policyNumber' element={<UpdatePayment />} />
								<Route path='/contacts' element={<ContactDetails />} />
								<Route path='/contacts/update' element={<ContactDetailsUpdate />} />
								<Route path='/login' element={<Login />} />
								<Route path='/logout' element={<Logout />} />
								<Route path="*" element={<Error404 />} />
							</Routes>
						)}
						{!loggedIn && (
							<Login />
						)}
					</Brand>
				</Auth>
			</Router>
		</GTMProvider>
	);
};

// Exports
export default App;
