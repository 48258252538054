import React from "react";
import { useSelector } from 'react-redux';

// Selectors
import { headingSelector } from '../../../store/selectors/brand';
import { dateFormatRegionSelector, policyPageConfigSelector } from "../../../store/selectors/config";

// Utility
import { formatDate } from '../../../util/formats';

// Components
import Table from "../../Layout/Table";
import TableRow from "../../Layout/TableRow";


const DetailPolicySummary = ({policy, isInactivePolicy}) => {    
	const sectionHeading = useSelector(state => headingSelector(state, 'policySummary'));
    const labels = useSelector(policyPageConfigSelector);        
    const dateFormatRegion = useSelector(dateFormatRegionSelector);
    return (<>
        <div className='detail-heading'><h3>{sectionHeading}</h3></div>
        <Table className='policy-details-summary'>
                <TableRow heading={'Policy Number' } value={policy.PolicySummary.PolicyNumber}/>
                <tr className={'policy-owner-name'}><td key={1}>{labels.policyOwnerLabel}</td><td key={2} className='dd-privacy-mask'><div>{policy.Owner.Title + ' ' + policy.Owner.FirstName + ' ' + policy.Owner.Lastname}</div></td></tr>
                <TableRow heading={'Policy Status'} value={policy.PolicyStatus.Description} />
                <TableRow heading={labels.policyStartLabel} value={formatDate(policy.PolicySummary.FirstCollectionDate, dateFormatRegion)} />
                { isInactivePolicy && policy.PolicySummary.PolicyTerminationDate &&
                    <TableRow heading={'Policy End Date'} value={formatDate(policy.PolicySummary.PolicyTerminationDate, dateFormatRegion)} />
                }
                { !isInactivePolicy && policy.PolicySummary.RenewalDate && 
                    <TableRow heading={labels.policyRenewalLabel} value={formatDate(policy.PolicySummary.RenewalDate, dateFormatRegion)} />
                }
                
         </Table>
    </>)
}

export default DetailPolicySummary;
