// convert pdf base 64 to link
export const convertPdfBase64ToLink = (base64Data) => {
  const byteString = window.atob(base64Data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  return url;
};

// Checks if the browser has pdfviewer or not
export const  isBrowserHasPDFViewer = () => {

  // Modern borwsers
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/pdfViewerEnabled
  if (navigator.pdfViewerEnabled !== undefined) {
      return navigator.pdfViewerEnabled;
  }

  // Old browsers or not compatible with pdfViewerEnabled like Safari
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/mimeTypes
  let hasPDFViewer = false;
  try {
      var hasPluginEnabled = navigator.mimeTypes && navigator.mimeTypes["application/pdf"]
          ? navigator.mimeTypes["application/pdf"].enabledPlugin
          : null
      ;

      if (hasPluginEnabled) {
          hasPDFViewer = true;
      }
  } catch (e) {
      hasPDFViewer = false;
  }

  return hasPDFViewer;
}