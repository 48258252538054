export const addMaskAfterFormLoad = (formselector, maskSelector) => {
    waitForElementToExist(formselector).then(element => {
        var elements = element.querySelectorAll(maskSelector);
        for (var counter = 0; counter < elements.length; counter++) {
            if (!elements[counter].classList.contains('dd-privacy-mask'))
                elements[counter].classList.add('dd-privacy-mask');
        }
    });
}

const waitForElementToExist = (selector) => {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(() => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            subtree: true,
            childList: true,
        });
    });
}
