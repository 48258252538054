

// Externals
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { getAddressMatches, getFormattedAddress, resetAddressMatches } from '../../store/actions/address';

// Selectors
import { addressMatchesSelector, postalAddressMatchesSelector } from '../../store/selectors/address';

// Styles
import './AddressLookup.scss';

// Utility
import { toTitleCase } from '../../util/formats';

const formatAddress = (address) => {
	if (address.match(/^(.+;){3}.+$/)) {
		const a = address.split(';');
		return toTitleCase(a[0]) + ' '
			+ toTitleCase(a[1]) + ' '
			+ a[2].toUpperCase() + ' '
			+ a[3].toUpperCase()
	}
	else { 
		return address;
	}
}

// Update Contact Details
const AddressLookup = (props) => {

	const { isResidential, className } = props;
	const dispatch = useDispatch();

	const [ addressLookup, setAddressLookup ] = useState('');
	const [ searchActive, setSearchActive ] = useState(false);
	const addressMatches = useSelector(isResidential ? addressMatchesSelector : postalAddressMatchesSelector);
	const searchRef = useRef(null);

	useEffect(() => {
		// empty the address matches on unmount
		return () => {
			dispatch(resetAddressMatches());
		};
	}, [ dispatch ]);

	useEffect(() => {
		if (addressLookup.length > 3)
			dispatch(getAddressMatches({addressText: addressLookup, isResidential: isResidential}));
	}, [ addressLookup, dispatch, isResidential ]);

	const handleChange = (e) => {
		setAddressLookup(e.target.value);
	};

	const handleFocus = () => {
		setAddressLookup('');
		setSearchActive(true);
	};

	const handleBlur = () => {
		setSearchActive(false);
	};

	const handleSelectAddress = (address) => {
		// format the address
		// populate the pendingUpdate
		// AUPortals = matchPostalAddress is not included in this action - its not sent to the API here though. It is just returned to the reducer
		dispatch(getFormattedAddress({ address: address, isResidential: props.isResidential, matchPostalAddress: isResidential ? props.matchPostalAddress : false }));
		setAddressLookup('');
		if(props.onComplete){
			props.onComplete(props.isResidential);
		}
		if(searchRef && searchRef.current){
			searchRef.current.blur();
		}
	};

	const placeholder = !searchActive && props.address ? props.address : 'Enter your street address';

	return (
		<div className={'form-contact' + (!props.isResidential? '-postal' : '') + '-address-lookup'}>

			<input name='addressLookup' type='text'
				className={className + ' dd-privacy-mask'}
				ref={searchRef}
				placeholder={placeholder}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onChange={handleChange}
				value={formatAddress(addressLookup)} />

			
			<div className='address-matches dd-privacy-mask'>
				{addressLookup.length > 3 && searchActive && addressMatches
				.filter( address => address !== addressLookup)
				.map((address, index) => (
					<p onClick={() => handleSelectAddress(address)} onMouseDown={(e) => e.preventDefault() } key={index}>{formatAddress(address)}</p>
				))}
			</div>
			
		</div>
	);
};

// Exports
export default AddressLookup;
