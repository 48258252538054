// External
import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-sessionstorage-simple';

// Reducers
import rootReducer from './reducers';

// Middlewares
import authMiddleware from './middleware/auth';
import contactMiddleware from './middleware/contact';
import policyMiddleware from './middleware/policy';
import paymentMiddleware from './middleware/payment';

// ENV Config
import config from '../config';

// Debug
const debug = config.DEBUG;
// Store
const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			// these useful debugging tools add rendering latency and can be disabled
			immutableCheck: debug, 
			serializableCheck: debug
		}).concat(
			authMiddleware,
			contactMiddleware,
			policyMiddleware,
			paymentMiddleware,
			save()
		);
	},
	preloadedState: load(),
	devTools: debug
});

//Setting up store for Unit Test Cases for passing preload state
export const setupStore = preloadedState => {
	return configureStore({
		reducer: rootReducer,
		preloadedState,
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			// these useful debugging tools add rendering latency and can be disabled
			immutableCheck: debug,
			serializableCheck: debug
		}).concat(
			authMiddleware,
			contactMiddleware,
			policyMiddleware,
			paymentMiddleware,
			save()
		);
	}
	})
};

// Exports
export default store;
