// Externals
import React, { Suspense, lazy } from 'react';

// Customised App - Canada
const FiftyUpApp = lazy(() => import('./FiftyUp/App'));
const NorthCoverApp = lazy(() => import('./NorthCover/App'));
const SeniorsApp = lazy(() => import('./AustralianSeniors/App'));
const RealApp = lazy(() => import('./RealInsurance/App'));

// Brand HOC to return selected brand
const Brand = (props) => {
	const { brand } = props;

	// Configured Themes
	const themes = {
		fiftyup: <FiftyUpApp {...props} />,
		northcover: <NorthCoverApp {...props} />,
		seniors: <SeniorsApp {...props} />,
		real: <RealApp {...props} />
	};

	// Render
	return (
		<Suspense fallback={<></>}>
			{((brand && themes[brand]) && themes[brand]) || <p>No theme found</p> }
		</Suspense>
	);

};

// Exports
export default Brand;
