import config from "../config"

const countryId = config.DEFAULT_COUNTRY_ID;
export default function httpRequest(url, requestConfig) {
  var alteredConfig = {
    ...(requestConfig || {}),
    headers: {
      ...(requestConfig?.headers || {}),
      CountryId: countryId
    }
  }

  return fetch(url, alteredConfig)
}