// Externals
import { createSlice } from '@reduxjs/toolkit';

// Actions 
import { 
	addPolicySummaryFromContact, 
	getPolicyDetail, 
	getPolicyOutstandingPayments,
	setPolicyStatusPaymentPending,
	setPolicyCount,
	updatePolicyStatus,
	clearPolicies } from '../actions/policy';

// Utils
import { sortDateAscending } from '../../util/sort';

// Initial State
const initialState = {
	loadingPolicySummaries: false,
	loadingPolicyDetail: false,
	policySummaries: {},
	policyDetails: {},
	outstandingPayments: {},
	policyCount: null,
	policiesLoaded: 0,
	policiesPaymentsLoaded: 0,
	policiesOutstanding: 0
};

// Policy Reducer
const policy = createSlice({
	name: 'policy',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		//Set the expected number of policies to load
		builder.addCase(setPolicyCount, (state, action) => {
			state.policyCount = action.payload;
		});

		// Add the policy summary (excerpt included with contacts API result)
		builder.addCase(addPolicySummaryFromContact, (state, action) => {
			state.policySummaries[action.payload.PolicyNumber] = action.payload.Product;
			state.policySummaries[action.payload.PolicyNumber].PolicyNumber = action.payload.PolicyNumber;
			state.policySummaries[action.payload.PolicyNumber].CoverAmount = action.payload.OwnerMandatoryBenefitSI;
			state.policySummaries[action.payload.PolicyNumber].RenewalDate = action.payload.RenewalDate;
			state.policySummaries[action.payload.PolicyNumber].SaleCompletedDate = action.payload.SaleCompletedDate;
			state.policySummaries[action.payload.PolicyNumber].ClaimInProgress = action.payload.ClaimInProgress;

			// policy status 
			// (As discussed with StackHolders, we want to show PolicyStatus instead of Claim in Progress)
			// if (action.payload.ClaimInProgress) {
			// 	state.policySummaries[action.payload.PolicyNumber].PolicyStatus = 'Claim in Progress';
			// }
			// else
			switch(action.payload.PolicyStatusId) {
				case 20:
					state.policySummaries[action.payload.PolicyNumber].PolicyStatus = 'Accepted';
					break;
				case 30:
					state.policySummaries[action.payload.PolicyNumber].PolicyStatus = 'Active';
					break;
				case 40:
					state.policySummaries[action.payload.PolicyNumber].PolicyStatus = 'Payment Overdue';
					break;
				case 50:
					state.policySummaries[action.payload.PolicyNumber].PolicyStatus = 'Lapsed';
					break;
				case 60:
					state.policySummaries[action.payload.PolicyNumber].PolicyStatus = 'Cancelled';
					break;
				case 61:
					state.policySummaries[action.payload.PolicyNumber].PolicyStatus = 'Cancelled';
					break;
				default:
			}
		});
 
		// Load the full policy details
		builder.addCase(getPolicyDetail.pending, (state, action) => {
			state.loadingPolicyDetail = true;
		});
		builder.addCase(getPolicyDetail.rejected, (state, action) => {
			state.errorMessage = action.payload.Message;
			state.loadingPolicyDetail = false;
			state.policiesLoaded = state.policiesLoaded + 1
		});
		builder.addCase(getPolicyDetail.fulfilled, (state, action) => {
			state.policyDetails[action.payload.PolicySummary.PolicyNumber] = action.payload;
			state.policiesLoaded = state.policiesLoaded + 1;
			// policy status
			// Policy status (As discussed with StackHolders, we want to show PolicyStatus instead of Claim in Progress)
			//if (state.policySummaries[action.payload.PolicySummary.PolicyNumber].ClaimInProgress) {
			//	state.policyDetails[action.payload.PolicySummary.PolicyNumber].PolicyStatus.Description = 'Claim in Progress';
			//}
			//else
			 switch(action.payload.PolicyStatus.PolicyStatusId) {
				case 20:
					state.policyDetails[action.payload.PolicySummary.PolicyNumber].PolicyStatus.Description = 'Accepted';
					break;
				case 30:
					state.policyDetails[action.payload.PolicySummary.PolicyNumber].PolicyStatus.Description = 'Active';
					break;
				case 40:
					state.policyDetails[action.payload.PolicySummary.PolicyNumber].PolicyStatus.Description = 'Payment Overdue';
					break;
				case 50:
					state.policyDetails[action.payload.PolicySummary.PolicyNumber].PolicyStatus.Description = 'Lapsed';
					break;
				case 60:
					state.policyDetails[action.payload.PolicySummary.PolicyNumber].PolicyStatus.Description = 'Cancelled';
					break;
				case 61: 
					state.policyDetails[action.payload.PolicySummary.PolicyNumber].PolicyStatus.Description = 'Cancelled';
					break;
				default:
			}

			state.policyDetails[action.payload.PolicySummary.PolicyNumber].Owner.Title = state.policyDetails[action.payload.PolicySummary.PolicyNumber].Owner.Title.toLowerCase();
			state.policyDetails[action.payload.PolicySummary.PolicyNumber].Owner.FirstName = state.policyDetails[action.payload.PolicySummary.PolicyNumber].Owner.FirstName.toLowerCase();
			state.policyDetails[action.payload.PolicySummary.PolicyNumber].Owner.Lastname = state.policyDetails[action.payload.PolicySummary.PolicyNumber].Owner.Lastname.toLowerCase(); 

			for(var i = 0; i < state.policyDetails[action.payload.PolicySummary.PolicyNumber].LifeInsured.length; i++){
				state.policyDetails[action.payload.PolicySummary.PolicyNumber].LifeInsured[i].FirstName = state.policyDetails[action.payload.PolicySummary.PolicyNumber].LifeInsured[i].FirstName.toLowerCase();
				state.policyDetails[action.payload.PolicySummary.PolicyNumber].LifeInsured[i].LastName = state.policyDetails[action.payload.PolicySummary.PolicyNumber].LifeInsured[i].LastName.toLowerCase();
				state.policyDetails[action.payload.PolicySummary.PolicyNumber].LifeInsured[i].BenefitDetails.sort((x, y) => x.BenefitDescription.normalize().localeCompare(y.BenefitDescription.normalize()) || sortDateAscending(x.RiskStartDate, y.RiskStartDate));
			}
			state.loadingPolicyDetail = false;
		});

		
		// Populate the outstanding payments
		builder.addCase(getPolicyOutstandingPayments.pending, (state, action) => {
			// 
		});
		builder.addCase(getPolicyOutstandingPayments.rejected, (state, action) => {
			//
			state.policiesPaymentsLoaded = state.policiesPaymentsLoaded + 1;
		});
		builder.addCase(getPolicyOutstandingPayments.fulfilled, (state, action) => {
			if (action.payload.Payments.length > 0) {
				// populate the outstanding payments
				state.outstandingPayments[action.meta.arg] = action.payload.Payments;
				state.policiesOutstanding += 1;
			}
			else if (state.outstandingPayments[action.meta.arg]) {
				// clear any existing record of outstanding payments
				delete state.outstandingPayments[action.meta.arg];
			}
			state.policiesPaymentsLoaded += 1;
		});

		// Update the current policy status from the API
		builder.addCase(updatePolicyStatus.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(updatePolicyStatus.rejected, (state, action) => {
			state.loading = false;
			state.errorMessage = action.payload.Message;
		});
		builder.addCase(updatePolicyStatus.fulfilled, (state, action) => {
			state.loading = false;
			const policyNumber = action.payload.PolicySummary.PolicyNumber;
			const policyStatusId = action.payload.PolicyStatus.PolicyStatusId;

			// Status ID
			state.policyDetails[policyNumber].PolicyStatus.PolicyStatusId = policyStatusId;

			// Status Description
			// Policy status (As discussed with StackHolders, we want to show PolicyStatus instead of Claim in Progress)
			//if (state.policySummaries[action.payload.PolicySummary.PolicyNumber].ClaimInProgress) {
			//	state.policyDetails[policyNumber].PolicyStatus.Description = 'Claim in Progress';
			//	state.policySummaries[policyNumber].PolicyStatus = 'Claim in Progress';
			//}
			//else
			switch(policyStatusId) {
				case 20:
					state.policyDetails[policyNumber].PolicyStatus.Description = 'Accepted';
					state.policySummaries[policyNumber].PolicyStatus = 'Accepted';
					break;
				case 30:
					state.policyDetails[policyNumber].PolicyStatus.Description = 'Active';
					state.policySummaries[policyNumber].PolicyStatus = 'Active';
					break;
				case 40:
					state.policyDetails[policyNumber].PolicyStatus.Description = 'Payment Overdue';
					state.policySummaries[policyNumber].PolicyStatus = 'Payment Overdue';
					break;
				default:
			}
		});

		// Action for the frontend to make the policy status as "Processing Payment"
		builder.addCase(setPolicyStatusPaymentPending, (state, action) => {
			const policyNumber = action.payload;
			if (state.policySummaries[policyNumber]) {
				state.policySummaries[policyNumber].PolicyStatus = 'Processing Payment';
			}
			if (state.policyDetails[policyNumber]) {
				state.policyDetails[policyNumber].PolicyStatus.PolicyStatusId = null;
				state.policyDetails[policyNumber].PolicyStatus.Description = 'Processing Payment';
			}
		});

		// Clear the policies (for Logout)
		builder.addCase(clearPolicies, (state, action) => {
			Object.assign(state, initialState);
		});

	}
});

// Exports
export default policy.reducer;
