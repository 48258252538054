// Utility
import { formatDate } from '../../util/formats';
import { sortDateAscending, sortPolicyStatus } from '../../util/sort';

// return policy summary object as array, sorted by PolicyTypeID and Policy Commencement Date (aka SaleCompletedDate)
export const policySummariesSelector = (state) => Object.keys(state.policy.policySummaries).map(key => 
	state.policy.policySummaries[key]
).sort((a, b) => {
	return sortPolicyStatus(a.PolicyStatus, b.PolicyStatus) || a.ProductType.ProductTypeId - b.ProductType.ProductTypeId || sortDateAscending(a.SaleCompletedDate, b.SaleCompletedDate);
})

// return the policy summary
export const policySummarySelector = (state, policyNumber) => state.policy.policySummaries[policyNumber];

// return the policy detail
export const policyDetailSelector = (state, policyNumber) => state.policy.policyDetails[policyNumber];

// return loading status of PolicyDetail
export const policyDetailIsLoadingSelector = (state) => state.policy.isLoadingPolicyDetail;

// used as a guard for /policy/:policyNumber URIs
export const validPoliciesSelector = state => Object.keys(state.policy.policySummaries);

// select policy ids for policies with status Lapsed, Cancelled or NTU
export const inactivePoliciesSelector = state => Object.keys(state.policy.policyDetails).filter(x => state.policy.policyDetails[x].PolicyStatus.PolicyStatusId > 40);

// check if all policies are inactive
export const inactiveOnlySelector = state => Object.keys(state.policy.policySummaries).filter(x => state.policy.policySummaries[x].PolicyStatus !== 'Lapsed' && state.policy.policySummaries[x].PolicyStatus !== 'Cancelled').length === 0;

// used as a guard for /payment/:policyNumber URIs
export const overduePoliciesSelector = state => Object.keys(state.policy.outstandingPayments);

// return outstanding/overdue payment details for banner, keyed by policy
export const overduePaymentsBannerSelector = (state) => {
	const policySummaries = state.policy.policySummaries;
	if (Object.keys(policySummaries).length === 0) return null;
	
	const outstandingPayments = Object.keys(state.policy.outstandingPayments);
	const pendingPayments = state.payment.payNowPoliciesPendingPayment;
	if (outstandingPayments.length === 0 && pendingPayments.length === 0) return null;

	const payable = outstandingPayments.filter(policyNumber => !pendingPayments.includes(policyNumber)).map(policyNumber => {
		const transactions = state.policy.outstandingPayments[policyNumber];
		return {
			policyNumber: policyNumber,
			policyName: policySummaries[policyNumber].ProductName,
			overdueCount: transactions.length,
			totalDue: transactions.reduce((sum, item) => sum + item.Amount, 0)
		}
	});
	const pending = pendingPayments.map(policyNumber => ({
		policyNumber: policyNumber,
		policyName: policySummaries[policyNumber].ProductName,
		hasPending: true
	}));
	return [...payable, ...pending];
};

// return outstanding/overdue payment details for policy 
// (used by Pay Now to generate the selectable list of payments)
export const overduePolicyPaymentsSelector = (state, policyNumber) => {
	if (Object.keys(state.policy.outstandingPayments).length === 0) return null;
	const p = state.policy.outstandingPayments[policyNumber];
	return p.length === 0 ? null 
		: p.map(payment => ({
			transactionId: payment.TransactionId,
			dueDate: formatDate(payment.RegularCollectionDate, state.config.regionalisation.dates.region),
			sortDate: payment.RegularCollectionDate,
			amount: payment.Amount
		})
	).sort((a, b) => {
		return sortDateAscending(a.sortDate, b.sortDate);
	});
};

// return true if the policyNumber has overdue payments which are not currently in process
export const policyHasOverduePaymentsSelector = (state, policyNumber) => {
	return Object.keys(state.policy.outstandingPayments).filter(
		policyNumber => !state.payment.payNowPoliciesPendingPayment.includes(policyNumber)
	).includes(policyNumber);
};

export const allPoliciesLoadedSelector = (state) => state.policy.policyCount !== null && state.policy.policiesLoaded >= state.policy.policyCount && 
	Object.keys(state.policy.policyDetails).length <= state.policy.policiesLoaded;
export const allOutstandingPaymentsLoadedSelector = (state) => state.policy.policyCount !== null && state.policy.policiesOutstanding !== null && state.policy.outstandingPayments !== null &&
	state.policy.policiesPaymentsLoaded >= state.policy.policyCount && state.policy.policiesOutstanding === Object.keys(state.policy.outstandingPayments).length;
