// Externals
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Selectors
import { contactSelector, contactEditableSelector } from '../store/selectors/contact';
import { headingSelector } from '../store/selectors/brand';
import { regionalisationSelector, shouldSwapPhoneFieldsSelector } from '../store/selectors/config';

// Assets
import { ReactComponent as EditIcon } from '../static/pencil-solid.svg';

// Components
import FlashMessage from '../components/Widgets/FlashMessage';
import Container from '../components/Layout/Container';
import OverdueBanner from '../components/Widgets/OverdueBanner';

// Styles
import './ContactDetails.scss';

// Update Contact Details
const ContactDetails = () => {

  const contact = useSelector(contactSelector);
	const contactEditable = true; // useSelector(contactEditableSelector);
	const pageHeading = useSelector(state => headingSelector(state, 'myContactDetails'));
	const pageSubHeading = useSelector(state => headingSelector(state, 'customerContactDetails'));
	const region = useSelector(regionalisationSelector);
	const [ flashMessage, setFlashmessage ] = useState(false);
	const shouldSwapPhoneFields = useSelector(shouldSwapPhoneFieldsSelector);

	useEffect(() => {
		// show success flash message
		const p = new URLSearchParams(document.location.search);
		if (p.get('success')) setFlashmessage(true);
	}, []);

	return (<>

		<OverdueBanner />
		
		<Container>
			<div className='page-contact page-contact-details'>

				<h1>{pageHeading}</h1>

				{ flashMessage && <FlashMessage message={'Your contact details have been updated'} /> }

				<div className='header-container'>
					<h2>{pageSubHeading}</h2>

					{contactEditable && <div className='edit-button'>
							<Link to='/contacts/update'><EditIcon />Edit details</Link>
						</div>
					}

				</div>
				<dl>
					{/* <dt>Contact Name</dt>
					<dd><div className='contact-name-display'>{contact.FirstName + " " + contact.LastName}<div className='padlock-icon'></div></div></dd> */}
					<dt>{region.address.residential.label}</dt>
					<dd className='dd-privacy-mask'>{contact.homeAddress}</dd>

					<dt>{region.address.suburb.label}</dt>
					<dd className='dd-privacy-mask'>{contact.townCity}</dd>

					<dt>{region.address.state.label}</dt>
					<dd className='dd-privacy-mask'>{contact.state}</dd>

					<dt>{region.address.postCode.label}</dt>
					<dd className='dd-privacy-mask'>{contact.postcode}</dd>

					<dt className='pad'>{region.address.postal.label}</dt>
					<dd className='pad dd-privacy-mask'>{contact.postalAddress}</dd>

					<dt>{region.phone.mobile.label}</dt>
					<dd className='dd-privacy-mask'>{shouldSwapPhoneFields ? contact.landlinePhone : contact.mobilePhone}</dd>

					<dt>{region.phone.home.label}</dt>
					<dd className='dd-privacy-mask'>{shouldSwapPhoneFields ? contact.mobilePhone : contact.landlinePhone}</dd>

					<dt>{region.email.label}</dt>
					<dd className='email dd-privacy-mask'>{contact.email}</dd>
				</dl>
			</div>
		</Container>
	</>);
};

export default ContactDetails;
